p{
    color: white;
    font-family: 'Space Grotesk', sans-serif;
    font-size:150%;
}
h1{
    color: white;
    font-family: 'Space Grotesk', sans-serif;
}
.center {
    position: absolute;
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-left: -50px; /* margin is -0.5 * dimension */
    margin-top: -25px;
}

img{
      max-width: 40%;
      height: auto;
}
