.navbar {
  display: flex;
  flex-direction: row;
  background-color: #001220;
}

.navbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  color: inherit;
  text-decoration: inherit;
  text-transform: uppercase;
  outline: none;
}
